import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Input, Form, FormFeedback,
    Pagination, PaginationItem, PaginationLink, FormGroup, FormText
} from 'reactstrap';

const sleep = ms => new Promise(r => setTimeout(r, ms));
const pageSize = 1;

export class AdmImportData extends Component {
    static displayName = AdmImportData.name;
    static fltOperator = 0;
    static pgId = 0;
    static pgInvoiceNumber = "";
    static pgDateInv = "";
    static pgSupplier = "";
    static pgVesselCode = "";
    static pgVesselName = "";
    static pgPort = "";
    static pgApplication = "";
    static pgUnit = "";
    static pgCount = 0;

    async handleFilter(event) {
        AdmImportData.fltOperator = event.target.value;
        const data = await AdmImportData.invGet();
        const dbimpInvoices = data.impInvoices;
        this.setState({
            impInvoices: dbimpInvoices });
    }

    async handleModal(event) {
        let val = event.target.value;
        let mod = this.state.modal;
        if (mod === false) {
            if (val === "-1") {
                AdmImportData.modId = 0;
                AdmImportData.modRef = "";
                AdmImportData.modSupplier = 0;
                AdmImportData.modShortDesc = "";
                AdmImportData.modCat = 0;
                AdmImportData.modAssociation = null;
                this.setState({ modal: true });
            }
            else {
                let myrec = this.state.impInvoices.find(x => x.prdId === parseInt(val));
                AdmImportData.modId = myrec.prdId;
                AdmImportData.modRef = myrec.prdRefCode;
                AdmImportData.modSupplier = myrec.prdSupplier;
                AdmImportData.modShortDesc = myrec.prdShortDesc;
                AdmImportData.modCat = myrec.prdCat;
                AdmImportData.modAssociation = myrec.prdAssociation;
                this.setState({ modal: true });
            }
        }
        else
        if (val !== "-1")
        {
            if (val !== "0") {
                const res = await AdmImportData.prodSave();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("Product updated successfully");
                }
            } else {
                const res = await AdmImportData.prodAdd();
                if (res.result === "error") {
                    alert("Error: " + res.reason);
                    return;
                } else {
                    alert("product created successfully");
                }
            }
            const data = await AdmImportData.invGet();
            const dbimpInvoices = data.impInvoices;
            const dbsuppliers = data.suppliers;
            const dboperators = data.operators;
            this.setState({
                impInvoices: dbimpInvoices, suppliers: dbsuppliers,
                operators: dboperators, loading: false, modal: false
            });
        }
        else {
            this.setState({ modal: false });
        }   
    }

    constructor(props) {
        super(props);
        this.state = {
            impInvoices: [], suppliers: [], operators: [],
            loading: true, modal: false, page: 0
        };
        this.handleModal = this.handleModal.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.handleParse = this.handleParse.bind(this);
        this.handleImpAll = this.handleImpAll.bind(this);
    }

    componentDidMount() {
        this.populateData();
    }

    static renderImported(i, suppliers)
    {
        if (i == null) return (<div> No data </div>);

        AdmImportData.pgId = i.minvId;
        if (i.minvDateInv == null) i.minvDateInv = "";
        AdmImportData.pgDateInv = (i.minvDateInv.indexOf(" ") !== -1) ?
        i.minvDateInv.substring(0, i.minvDateInv.indexOf(" ")) : i.minvDateInv;
        AdmImportData.pgInvoiceNumber = i.minvInvoiceNumber;
        AdmImportData.pgSupplier = i.minvSupplier;
        AdmImportData.pgVesselCode = i.minvVesselCode;
        AdmImportData.pgVesselName = i.minvVesselName;
        AdmImportData.pgPort = i.minvPort;
        AdmImportData.pgApplication = i.minvApplication;
        AdmImportData.pgUnit = i.minvUnit;
        AdmImportData.pgCount = i.minvCount;
        var dateOk = (i.rDate.substring(0, 3) !== "000");


        // turn to 4 decimal places at same point
        const calc = (Math.round(i.rQuantity * i.rPrice * 10000) / 10000 === i.rLineTotal);
        return (
        <div class="container">
                <Form>
                    <div class="row">
                        <div class="col-lg-3">
                            <Label><b>Invoice</b></Label>
                            <Input onChange={(e) => AdmImportData.minvInvoiceNumber = e.target.value}
                                id="pgInvoiceNumber"
                                type="text"
                                value={i.minvInvoiceNumber}
                            >
                            </Input>
                            <Input onChange={(e) => AdmImportData.pgDateInv = e.target.value}
                                id="pgDateInv"
                                type="text"
                                value={AdmImportData.pgDateInv}
                                valid={(dateOk) ? "1" : ""} invalid={(!dateOk) ? "1" : ""} >
                            </Input>
                        </div>
                        <div class="col-lg-3">
                            <Label><b>Supplier</b></Label>
                            <Input onChange={(e) => AdmImportData.pgSupplier = e.target.value}
                                id="pgSupplier"
                                type="text"
                                value={AdmImportData.pgSupplier}
                                valid={(i.minvSupplierId != null) ? "1" : ""} invalid={(i.minvSupplierId == null) ? "1" : ""}
                            > </Input>
                            <Input value={(i.minvSupplierId == null) ? "" :
                                (suppliers.find(x => x.supId === i.minvSupplierId).supName)} disabled />
                        </div>
                        <div class="col-lg-3">
                            <Label><b>Vessel</b></Label>
                            <Input onChange={(e) => AdmImportData.pgVesselCode = e.target.value}
                                id="pgVesselCode"
                                type="text"
                                value={AdmImportData.pgVesselCode}
                                valid={(i.minvVesselSysCode == AdmImportData.pgVesselCode) ? "1" : ""} invalid={(i.minvVesselSysCode != AdmImportData.pgVesselCode) ? "1" : ""}
                            > </Input>
                            <Input onChange={(e) => AdmImportData.pgVesselName = e.target.value}
                                id="pgVesselName"
                                type="text"
                                value={AdmImportData.pgVesselName}
                                valid={(i.minvVesselId != null) ? "1" : ""} invalid={(i.minvVesselId == null) ? "1" : ""}
                            > </Input>
                        </div>
                        <div class="col-lg-3">
                            <Label><b>Port/Country</b></Label>
                            <Input onChange={(e) => AdmImportData.pgPort = e.target.value}
                                id="pgPort"
                                type="text"
                                value={AdmImportData.pgPort}
                                valid={(i.minvPortId != null) ? "1" : ""} invalid={(i.minvPortId == null) ? "1" : ""}
                            > </Input>
                            <Input value={i.minvPortCountry ?? ""} disabled></Input>
                        </div>
                    </div>
                    <div class="row" style={{ "marginTop": "10px" }}>
                        <div class="col-lg-3">
                            <Label><b>Unit</b></Label>
                            <Input 
                                id="pgUnit"
                                type="text"
                                value={AdmImportData.pgUnit}
                                valid={(i.minvType != "None") ? "1" : ""} invalid={(i.minvType == "None") ? "1" : ""}
                            > </Input>
                            <Input value={(i.minvSysUnit == null) ? "none" : i.minvSysUnitQua + " x " + i.minvSysUnit} disabled />
                        </div>
                        <div class="col-lg-3">
                            <Label><b>Item</b></Label>
                            <Input value={i.minvItemRefCode}
                                valid={((i.minvItemId != null) && ((i.minvType === "Product"))) ? "1" : ""}
                                invalid={((i.minvItemId == null) && ((i.minvType === "Product"))) ? "1" : ""}
                            > </Input>
                            <Input style={{
                                "fontWeight": "bold",
                                "background": (i.minvType === "Product") ? "bisque" : "cadetblue"
                            }}
                                value={i.minvType} /> 
                        </div>
                        <div class="col-lg-3">
                            <Label><b>System Item</b></Label>
                            <Input value={i.minvSysName ?? ""} disabled />
                            <Input value={i.minvSysType ?? ""} disabled />
                        </div>
                        <div class="col-lg-3">
                            <Label><b>Status</b></Label>
                            <Input value={i.minvStatus ?? ""} disabled
                                style={{ "background": (i.minvStatus === "hold") ? "orange" : "#e9ecef" }}
                            > </Input>
                            <Input value={i.minvAction ?? ""} disabled
                            > </Input>
                        </div>
                    </div>
                    <div class="row" style={{ "marginTop": "10px" }}>
                        <div class="col-lg-3">
                            <Label><b>Packaging</b></Label>
                            <Input value={i.minvPackaging}
                                valid={(i.minvSysPack != "None") ? "1" : ""} invalid={(i.minvSysPack == "None") ? "1" : ""}
                            />
                            <Input value={i.minvSysPack} disabled />
                            <Label style={{ "marginTop": "10px" }}><b>Quantity</b></Label>
                            <Input value={i.minvQuantity} style={{ "text-align": "right" }} > </Input>
                            <Input value={i.rQuantity} style={{ "text-align": "right" }} disabled></Input>
                        </div>
                        <div class="col-lg-3">
                            <Label><b>Order / Delively Dates</b></Label>
                            <Input value={i.minvDateDeliver} disabled />
                            <Input value={i.minvDateOrder} disabled />
                            <Label style={{ "marginTop": "10px" }}><b>Item Price</b></Label>
                            <Input value={i.minvPrice} style={{ "text-align": "right" }}> </Input>
                            <Input value={i.rPrice} style={{ "text-align": "right" }} disabled></Input>
                        </div>
                        <div class="col-lg-3">
                            <Label><b>Currency</b></Label>
                            <Input value={i.minvCurrency} disabled />
                            <Input value="" style={{ "background":"white", "border":"0px" }} disabled/>
                            <Label style={{ "marginTop": "10px" }}><b>Total</b></Label>
                            <Input value={i.minvLineTotal} style={{ "text-align": "right", "padding-right": "38px" }}> </Input>
                            <FormGroup className="position-relative">
                                <Input value={i.rLineTotal} style={{ "text-align": "right" }} disabled
                                    valid={calc ? "1" : ""} invalid={calc ? "" : "1"}></Input>
                                <FormFeedback tooltip style={
                                    { "width": "100%", "text-align": "right", "padding-right": "38px" }}>
                                    {"Calc: " + (Math.round(i.rQuantity * i.rPrice * 10000) / 10000)}
                                </FormFeedback>
                            </FormGroup>
                        </div>
                        <div class="col-lg-3">
                            <Label><b>Error(s):</b></Label>
                            <Input type="textarea" rows="7" cols="30" style={{ "width": "100%", "resize": "none" }}
                            value={i.minvError} disabled/>
                        </div>
                    </div>
                </Form>
        </div>
    );}

    async handleParse(event) {
        const formData = new FormData();
        formData.append("member", AdmImportData.fltOperator);
        formData.append("start", AdmImportData.pgCount);
        formData.append("end", AdmImportData.pgCount);
        const token = await authService.getAccessToken();
        const res = await fetch('mar1/fileupload',
            { method: 'PUT', headers: !token ? {} : { 'Authorization': `Bearer ${token}` }, body: formData });
        if (!res.ok) {
            alert("Error: " + res.status);
        }
        const data = await AdmImportData.invGet();
        const dbimpInvoices = data.impInvoices;
        const dbsuppliers = data.suppliers;
        this.setState({ impInvoices: dbimpInvoices, suppliers: dbsuppliers, loading: false });
    }


    async handleSave(event) {
        await this.doSave();
        const data = await AdmImportData.invGet();
        const dbimpInvoices = data.impInvoices;
        const dbsuppliers = data.suppliers;
        this.setState({ impInvoices: dbimpInvoices, suppliers: dbsuppliers, loading: false });
    }

    async doSave() {
        const token = await authService.getAccessToken();
        var callString = 'mar1/impinvoices/' + AdmImportData.pgId;
        var requestOptions = {
            method: 'PUT',
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            body: JSON.stringify(
                {
                    MinvId: AdmImportData.pgId,
                    MinvMember: AdmImportData.fltOperator,
                    MinvSupplier: AdmImportData.pgSupplier,
                    MinvInvoiceNumber: AdmImportData.pgInvoiceNumber,

                    MinvDateInv: AdmImportData.pgDateInv,
                    MinvVesselCode: AdmImportData.pgVesselCode,
                    MinvVesselName: AdmImportData.pgVesselName,
                    MinvPort: AdmImportData.pgPort,
                    MinvApplication: AdmImportData.pgApplication,
                    MinvUnit: AdmImportData.pgUnit,
                    MinvStatus: "new",
                    MinvAction: ""
                }
            )
        };
        var res = await fetch(callString, requestOptions);
        if (!res.ok) {
            alert("Error: " + res.status);
            return;
        }
        const formData = new FormData();
        formData.append("member", AdmImportData.fltOperator);
        formData.append("start", AdmImportData.pgCount);
        formData.append("end", AdmImportData.pgCount);
        res = await fetch('mar1/fileupload',
            { method: 'PUT', headers: !token ? {} : { 'Authorization': `Bearer ${token}` }, body: formData });
        if (!res.ok) {
            alert("Error: " + res.status);
        }

        return;
    }


    async handleImp1(event) {
        alert("Importing 1 ...");
    }

    async onTimeoutAll(clines) {
        sleep(2000);
        return "ok";
    }

    async handleImpAll(event) {
        // call controller to import from impinvoices to arcinvoices
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/imp2arc/' + AdmImportData.fltOperator, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        if (response.ok) {
            alert("All data imported");
            const data = await AdmImportData.invGet();
            const dbimpInvoices = data.impInvoices;
            const dbsuppliers = data.suppliers;
            this.setState({ impInvoices: dbimpInvoices, suppliers: dbsuppliers, page: 0 });
        }
        else {
            if (response.status === 504) {
                // gateway timeout
                const clines = this.state.impInvoices.length;
                const res = await this.onTimeoutAll(clines); 
                if (res === "ok")
                {
                    alert("All data imported");
                    const data = await AdmImportData.invGet();
                    const dbimpInvoices = data.impInvoices;
                    const dbsuppliers = data.suppliers;
                    this.setState({ impInvoices: dbimpInvoices, suppliers: dbsuppliers, loading: false });
                }
                else
                    alert("Error, transfer failed");
            } else
            alert("Error, transfer failed");
        }
    }

    handleDel1() {
        alert("Deleting 1 ...");
    }

    handleDelAll() {
        alert("Deleting All");
    }

    render() {
        // if (AdmImportData.fltOperator === 0) AdmImportData.fltOperator = parseInt(sessionStorage.getItem("myMember"));
        if (AdmImportData.fltOperator == null) AdmImportData.fltOperator = 0;
        var invs = [];
        var pages = 1;
        var page = 0;
        var paginationItems = [];
        const stl = { "box-shadow": "none" };
        if (!this.state.loading) {
            const sorted = [...this.state.impInvoices].sort(
                (a, b) => a.minvCount > b.minvCount);
            pages = Math.ceil(sorted.length / pageSize);
            page = this.state.page;
            if (pages > 1) {
                invs = sorted.slice((page) * pageSize, (page+1) * pageSize);
            } else {
                invs = sorted;
            }
        }

        const contents1 = this.state.loading
        ? <p><em>Loading...</em></p>
            : AdmImportData.renderImported(invs[0], this.state.suppliers);

        if ( (!this.state.loading) && (pages > 1) )
        {
            const spc = 6;
            const start = Math.max(0, page - spc);
            const end = Math.min(pages, page + spc +1);
            paginationItems = Array(end - start).fill('').map((i, index) => (
                <PaginationItem active={page === index + start}>
                    <PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: index + start })}>{index + start + 1}</PaginationLink>
                </PaginationItem >
            ));
            if (start > 0)
                paginationItems = [<PaginationItem><PaginationLink tag="button" style={stl}
                    onClick={() => this.setState({ page: 0 })}>1</PaginationLink></PaginationItem>,
                    <PaginationItem disabled><PaginationLink tag="button">...</PaginationLink></PaginationItem>]
                    .concat(paginationItems);
            if (end < pages)
                paginationItems = paginationItems.concat([<PaginationItem disabled>
                    <PaginationLink tag="button">...</PaginationLink></PaginationItem>,
                    <PaginationItem><PaginationLink tag="button" style={stl}
                        onClick={() => this.setState({ page: pages - 1 })}>{pages}</PaginationLink></PaginationItem>]);
        }

        console.log("rendering");
        return (
            <div>
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4">
                            <h1 id="tabelLabel">Imported Data
                            </h1>
                            <div class="col-lg-5">
                                <Label for="fltOperator">
                                    Member Name
                                </Label><br />
                                <select onChange={this.handleFilter}
                                    id="fltOperator"
                                    value={AdmImportData.fltOperator}>
                                    <option value="0">Select One</option>
                                    {this.state.operators.map((operator) =>
                                        <option value={operator.oprId}>{operator.oprName}</option>)}
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-8 border border-dark">
                            <Form>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <Button color="warning" style={{ "width": "100%", "marginTop": "7px" }}
                                            onClick={this.handleSave} >
                                            Make Changes
                                        </Button>
                                        <Button color="warning" style={{ "width": "100%", "marginTop": "7px" }}
                                            onClick={this.handleParse}>
                                            Parse Again
                                        </Button>
                                    </div>
                                    <div class="col-lg-4">
                                        <Button color="primary" style={{ "width": "100%", "marginTop": "7px" }}
                                            onClick={this.handleImp1} >
                                            Import This Item
                                        </Button>
                                        <Button color="primary" style={{ "width": "100%", "marginTop": "7px" }}
                                            onClick={this.handleImpAll} >
                                            Import All Valid
                                        </Button>
                                    </div>
                                    <div class="col-lg-4">
                                        <Button color="danger" style={{ "width": "100%", "marginTop": "7px" }}
                                            onClick={this.handleDel1} >
                                            Delete This Item
                                        </Button>
                                        <Button color="danger" style={{ "width": "100%", "marginTop": "7px" }}
                                            onClick={this.handleDelAll} >
                                            Delete All
                                        </Button>
                                    </div>
                                </div>
                            </Form>
                            <br />
                        </div>
                    </div>
                </div>
                <br />

                {contents1}

                <br />
                <br />
                <Pagination>
                    <PaginationItem><PaginationLink first tag="button" style={stl}
                        onClick={() => { this.setState({ page: 0 }) }} /> </PaginationItem>
                    <PaginationItem><PaginationLink previous tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.max(0, page - 1) })} />  </PaginationItem>
                    {paginationItems}
                    <PaginationItem><PaginationLink next tag="button" style={stl}
                        onClick={() => this.setState({ page: Math.min(pages-1, page+1) })} /> </PaginationItem>
                    <PaginationItem><PaginationLink last tag="button" style={stl}
                        onClick={() => this.setState({ page: pages-1 })} />  </PaginationItem>
                </Pagination>

                <Modal isOpen={this.state.modal} toggle={this.handleModal}>
                <ModalHeader toggle={this.handleModal}>Product</ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label for="modRef">Reference</Label>
                                <Input type="text" name="modRef" id="modRef"
                                    value={AdmImportData.modRef}
                                    onChange={(e) => AdmImportData.modRef = e.target.value}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="modSupplier">Supplier</Label>
                                <Input type="text" name="modSupplier" id="modSupplier"
                                    value={AdmImportData.modSupplier}
                                    onChange={(e) => AdmImportData.modSupplier = e.target.value}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="modShortDesc">Short Description</Label>
                                <Input type="text" name="modShortDesc" id="modShortDesc"
                                    value={AdmImportData.modShortDesc}
                                    onChange={(e) => AdmImportData.modShortDesc = e.target.value}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="modCat">Category</Label>
                                <Input type="text" name="modCat" id="modCat"
                                    value={AdmImportData.modCat}
                                    onChange={(e) => AdmImportData.modCat = e.target.value}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="modAssociation">Association</Label>
                                <Input type="text" name="modAssociation" id="modAssociation"
                                    value={AdmImportData.modAssociation}
                                    onChange={(e) => AdmImportData.modAssociation = e.target.value}
                                />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" value="1" onClick={this.handleModal}>Save</Button>{' '}
                        <Button color="secondary" value="0" onClick={this.handleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }

    static async invGet() {
        console.log("getdata" + AdmImportData.fltOperator);
        var dbimpInvoices = [];
        const token = await authService.getAccessToken();
        const response = await fetch('mar1/impinvoicesex/' + AdmImportData.fltOperator,
            { headers: !token ? {} : { 'Authorization': `Bearer ${token}` } });
            if (response.ok)  dbimpInvoices = await response.json();
        const response2 = await fetch('mar1/suppliers', { 
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dbsuppliers = await response2.json();
        const response3 = await fetch('mar1/operators', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const dboperators = await response3.json();

        return { impInvoices: dbimpInvoices, suppliers: dbsuppliers,
                 operators: dboperators
        };
    }

    async populateData() {
        const data = await AdmImportData.invGet();
        const dbimpInvoices = data.impInvoices;
        const dbsuppliers = data.suppliers;
        const dboperators = data.operators;
        this.setState({
            impInvoices: dbimpInvoices, suppliers: dbsuppliers,
            operators: dboperators, loading: false, modal: this.state.modal
        });
    }

}

